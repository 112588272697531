<template>
  <div class="page-xq">
    <div class="scrbox">
      <div class="wp900">
        <div class="m-detail_ue" :class="!show?'minh':''" v-if="detailsData">
          <div class="tit" v-if="detailsData.title">
            {{ detailsData.title }}
          </div>
          <div class="txt">
            <!--  <p style="text-align: center" v-if="detailsData.image">
              <img :src="detailsData.image" alt="" />
            </p> -->
            <div v-if="detailsData.content" v-html="detailsData.content"></div>
          </div>
        </div>
        <el-empty class="pop-emp" v-else description="暂无数据"></el-empty>

        <div class="page-btns">
          <div class="btn prev" @click="gotopage(pre_id)" v-if="pre_id != 0">
            <i class="el-icon-arrow-left"></i><span class="s">上一篇文章</span>
          </div>
          <div class="btn next" @click="gotopage(nex_id)" v-if="nex_id != 0">
            <i class="el-icon-arrow-right"></i><span class="s">下一篇文章</span>
          </div>
        </div>
      </div>
      <!-- <div class="back" @click="goback"><i class="iconfont">&#xe600;</i></div> -->
      <div class="wp900" v-if="show">
        <div class="m-xq-bot">
          <img src="@/assets/images/new/gif.gif" alt="" class="icn" />
          <p>设计合作微信：planificatrice</p>
          <p>Design cooperation WeChat: planificatrice</p>
          <br />
          <p>UIUX设计，网站建设，品牌设计，平面设计</p>
          <p>
            UIUX design, website development, branding design, graphic design
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Loading } from 'element-ui';
import $ from 'jquery'
export default {
  data () {
    return {
      detailsData: {},
      show: false,
      pre_id: '',
      nex_id: ''
    }
  },
  mounted () {

    $('.scrbox').scrollTop(0)
    window.scroll(0, 0)
  },
  created () {
    let id = this.$route.params.id || localStorage.getItem('yhgshareDetailsId')
    this.showDetail(id);
  },
  methods: {
    /*  goback () {
       this.$router.go(-1);
     }, */
    gotopage (id) {
      // this.showDetail(id)
      this.$router.push({
        path: '/sharedetails/' + id,

      })
    },
    showDetail (id) {
      let that = this

      let data = {
        id: id
      };
      that.$http
        .shareDetail(data)
        .then((res) => {
          if (res.ret == 0) {
            // console.log(res);
            // that.loading = false;
            let result = res.data
            that.detailsData = result
            that.pre_id = result.pre_id
            that.nex_id = result.nex_id

            setTimeout(function () {
              that.show = true
            }, 300);

            that.$nextTick(function () {
              window.scroll(0, 0)
              $('.scrbox').scrollTop(0)
            })

          } else {
          }
        })
    }
  },
  watch: {
    // 如果路由发生变化，再次执行该方法
    $route (to, from) {
      // console.log(to.params.id);
      this.showDetail(to.params.id)
    }
  }

}
</script>

<style >
</style>